import FindUser from "../components/common/FindUser"

type Props = {}

const DashboardPage = (props: Props) => {
    return (
        <FindUser />
    );
}

export default DashboardPage;