import axios, { AxiosResponse } from "axios";

const API = async (uri: string, method: string, data: any | null = null, authToken: string) => {
    try {
        let response : AxiosResponse<any, any>;

        const headers = {
            Authorization: `Bearer ${authToken}`
        };

        switch(method) {
            case 'get':
                response =  await axios.get(uri, { headers });
                break;
            case 'put':
                response =  await axios.put(uri, data, { headers });
                break;
            case 'post':
                response =  await axios.post(uri, data, { headers });
                break;
            default:
                throw new Error(`Unsupported method: ${method}`);
        }

        if(response.status === 200){
            return response.data;
        } else {
            throw new Error(response.statusText);
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export default API;