import { AppRoute } from './models/common/AppRoute';
import DashboardPage from './pages/DashboardPage';


const AppRoutes : AppRoute[] = [
    {
        path: "/",
        name: "Migrate Users",
        component: DashboardPage,
        isVisible: false
    },
];

export default AppRoutes;