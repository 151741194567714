import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import AppRoutes from "../../AppRoutes";
import { AppRoute } from '../../models/common/AppRoute';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../logo.svg';

type Props = {
    drawerWidth: number
}

const Nav = (props : Props) => {
    const drawerWidth = props.drawerWidth;
    const location = useLocation();

    const activeRoute = (routeName: string) => {
        return location.pathname.indexOf(routeName) > -1 ? true : false;
    };

    const createLinks = (routes : AppRoute[]) => {
        return routes.map((route) => {
            
            if(route.isVisible){
                return (<MenuItem key={route.name} component={<Link to={route.path} />} active={activeRoute(route.path)}>{route.name} </MenuItem>);
            }

            return;
        });
    };

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    marginTop: '78px',
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Link to={'/'} className="App-logo"><img src={logo} alt="logo" /></Link>
            <Divider />
            <Sidebar width={drawerWidth.toString()}>
                <div style={{ flex: 1, marginTop: '1em' }}>
                    <Menu>
                        {createLinks(AppRoutes)}
                    </Menu>
                </div>
            </Sidebar>
        </Drawer>
    );
}

export default Nav;