export interface Endpoints {
  api: {[key: string]: string};
  azureADB2C: {
    domain: string;
    redirectUri: string;
    clientId: string;
    policies: {[key: string]: string};
  };
} 
  
  export const loadEndpoints = async (): Promise<Endpoints> => {
    const response = await fetch('/Endpoints.json');
    if (!response.ok) {
      throw new Error(`Failed to load configuration: ${response.status}`);
    }
    return response.json();
  };
  