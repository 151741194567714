import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#043982',
    },
    secondary: {
      main: '#008e9c',
    },
  },
};