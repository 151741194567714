import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { Endpoints } from "./data/Endpoints";

export const createMsalInstance = (endPoints: Endpoints) => {
  const msalConfig = {
      auth: {
        clientId: endPoints.azureADB2C.clientId,
        authority: `https://${endPoints.azureADB2C.domain}.b2clogin.com/${endPoints.azureADB2C.domain}.onmicrosoft.com/${endPoints.azureADB2C.policies.signUpSignIn}`,
        knownAuthorities: [`${endPoints.azureADB2C.domain}.b2clogin.com`],
        redirectUri: endPoints.azureADB2C.redirectUri,
        navigateToLoginRequestUrl: false, 
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
      system: {
        loggerOptions: {
              loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                return;
            }
          }
        }
      }
    };
    return new PublicClientApplication(msalConfig);
  }

  export const loginRequest = {
    scopes: [
      "https://tidegeodesyssandbox.onmicrosoft.com/api/userservice/impersonate_user"
    ],
  };
