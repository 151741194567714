import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, MenuItem, Select, Checkbox, Tooltip, Button, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import { User } from '../../models/common/UserDto';
import InfoIcon from '@mui/icons-material/Info';
import { useEndpoints } from '../../utils/EndpointContext';
import { Account } from '../../models/common/AccountDto';
import API from '../../utils/ApiUtil';
import useAuthToken from '../../utils/useAuthToken';

const UserDetails: React.FC<{ user: User }> = ({ user }) => {
  const endpoints = useEndpoints();
  const getAuthToken = useAuthToken();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [branchId, setBranchId] = useState<string>(user.branchId);
  const [migrateOrders, setMigrateOrders] = useState<boolean>(false);
  
  const migrateUser = async () => {
    try {
      const authToken = await getAuthToken();

      //Update User Service
      await API(`${endpoints.api.user}UpdateUserBranch`, 'post', { UserId: user.id, BranchId: branchId }, authToken)
        .then(response => {
          console.log(response);
          setSuccessMessage("Updated Branch Id for User");
        });

      if(migrateOrders){
        //Update Order Service
        await API(`${endpoints.api.order}UpdateUserBranchId`, 'post', { UserId: user.id, FromBranchId: user.branchId, ToBranchId: branchId }, authToken)
        .then(response => {
          const successMessage = response.data.message;
          setSuccessMessage(successMessage);
        });
      }

    } catch (error) {
      console.error('Error migrating user:', error);
    }
  };

  const handleAccountChange = (event: SelectChangeEvent) => {
    setBranchId(event.target.value as string);
  }

  const handleMigrateOrdersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMigrateOrders(event.target.checked);
  }

  const renderAccountOption = (account : Account) => {
    if(account.isBranch && account.id !== user.branchId){
      return (<MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>);      
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>
              <strong>{user.lastName}, {user.firstName} - {user.email}</strong> (<small>{user.id}</small>)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Current Branch</TableCell>
            <TableCell>Branch to Migrate to</TableCell>
            <TableCell align="center">
              <Tooltip title="If enabled, this will migrate all the contacts orders from their current branch to the selected branch." placement="top">
                <div>Migrate Orders <InfoIcon color="secondary" fontSize="inherit" /></div>
              </Tooltip>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={user.id}>
            <TableCell>{user.branchName}<br /><em>{user.organisationName}</em></TableCell>
            <TableCell>
              {user.relatedAccounts.length > 0 ? (
                <Select
                  displayEmpty
                  fullWidth
                  onChange={handleAccountChange}
                >
                  {user.relatedAccounts.map(account => renderAccountOption(account))}
                </Select>
              ) : (
                "No branches"
              )}
            </TableCell>
            <TableCell align="center"><Checkbox onChange={handleMigrateOrdersChange} size="medium" /></TableCell>
            <TableCell align="right">
              <Button variant="contained" size="large" color="success" onClick={migrateUser}>
                Migrate
              </Button>
            </TableCell>
          </TableRow>
          {successMessage && (
            <TableRow>
              <TableCell colSpan={4}>{successMessage}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserDetails;
