import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const useAuthToken = () => {
    const { accounts, instance } = useMsal();

    const getAuthToken = async () => {
        if (!accounts[0]) {
            throw new Error("No user account found");
        }

        try {
            const authResponse = await instance.acquireTokenSilent({
                scopes: loginRequest.scopes,
                account: accounts[0]
            });
            return authResponse.accessToken;
        } catch (error) {
            throw new Error("Failed to acquire token");
        }
    };

    return getAuthToken;
};

export default useAuthToken;