import { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import UserDetails from './UserDetails';
import { FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup } from '@mui/material';
import { User } from '../../models/common/UserDto';
import { useEndpoints } from '../../utils/EndpointContext';
import API from '../../utils/ApiUtil';
import useAuthToken from '../../utils/useAuthToken';

const FindUser: React.FC = () => {
  const defaultSearchType = 'email';
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<User[] | null>(null);
  const [searchType, setSearchType] = useState<string>(defaultSearchType);
  const getAuthToken = useAuthToken();
  const endpoints = useEndpoints();

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleSearch = async () => {

    try {
      setError(null);
      setLoading(true);
      const authToken = await getAuthToken();
      let endpoint : string = defaultSearchType;

      switch (searchType) {
        case 'email':
          endpoint = `${endpoints.api.user}GetUsersByEmail`;
          break;
        case 'name':
          endpoint = `${endpoints.api.user}GetUsersByName`;
          break;
        case 'userId':
          endpoint = `${endpoints.api.user}GetUserById`;
          break;
        default:
          console.error(`Unknown search type: ${searchType}`);
      }

      const response = await API(`${endpoint}/${searchTerm}`, 'get', null, authToken);
      setSearchResults(response);
      setLoading(false);
    } catch (error : any) {
      console.error('Error fetching data:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const renderSearch = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    if(searchResults != null){
      return <Paper sx={{ mt: 2 }}>
        {searchResults.map((user) => (<UserDetails key={user.id} user={user} />))}
      </Paper>
    }
  };

  return (
    <div>
      <FormControl sx={{ mb: 2 }}>
        <FormLabel id="demo-radio-buttons-group-label">Search Options</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="email"
          name="radio-buttons-group"
        >
          <FormControlLabel value="email" onClick={() => setSearchType("email")} control={<Radio />} label="Email" />
          <FormControlLabel value="name" onClick={() => setSearchType("name")} control={<Radio />} label="Name" />
          <FormControlLabel value="userId" onClick={() => setSearchType("userId")} control={<Radio />} label="User ID" />
        </RadioGroup>
      </FormControl>

      <div>
        <TextField
          label="Search by Email, Name, User ID"
          variant="outlined"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          sx={{ width: '40%' }}
        />
        <Button sx={{ ml: 2, mt: 1 }} size="large" variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>

        {renderSearch()}
      </div>

    </div>
  );
};

export default FindUser;
